<template>
  <div>
    <router-link :to="{ name: 'admin.discounts.create' }" class="btn btn-primary btn-sm py-2 px-2 float-right">Add New</router-link>
    <h2 class="h3">Discount Codes</h2>
    <div class="card">
      <div class="card-body">
        <table class="table table-responsive-md table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Value</th>
              <th>Usage Left</th>
              <th>Expiry</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(code, i) in codes" :key="`code-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ code.code }}</td>
              <td>{{ code.value }}</td>
              <td>{{ code.left }}</td>
              <td>{{ code.expiry }}</td>
              <td>
                <a href="#" @click.prevent="remove(code)"><i class="lni lni-trash-can"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      codes: []
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get('/api/v1/admin/discounts').then(response => {
        this.codes = response.data.codes
        this.$loader.stop()
      })
    },

    remove(code) {
      this.$axios.delete(`/api/v1/admin/discounts/${code.id}`).then(() => {
        this.$toasted.show('Discount code has been removed', { duration: 5000 });
        this.fetch();
      })
    }
  }
}
</script>